import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../../services/user.service";
import {User} from "../../../models/user";
import {AuthenticationService} from "../../../services/authentication.service";

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent {
    showLanguageMenu = false;
    connectedUser!: User;
    showUserProfile = false;

    constructor(
        private translate: TranslateService,
        private userService: UserService,
        private authService: AuthenticationService
    ) {
        this.userService.getConnectedUser()
            .subscribe(user => this.connectedUser = user);
    }

    toggleLanguageMenu() {
        this.showLanguageMenu = !this.showLanguageMenu;
        this.showUserProfile = false;
    }

    selectLanguage(lang: string) {
        this.translate.use(lang);
    }

    toggleUserProfile() {
        this.showUserProfile = !this.showUserProfile;
        this.showLanguageMenu = false;
    }

    logout() {
        this.authService.logout();
    }
}
