import {Component, Input} from '@angular/core';
import {ContentItem} from "../../../models/ContentItem";

@Component({
  selector: 'app-job-card',
  templateUrl: './job-card.component.html',
  styleUrls: ['./job-card.component.scss']
})
export class JobCardComponent {

    @Input()
    job!: ContentItem

}
