import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationService} from "./services/authentication.service";
import {Router} from "@angular/router";
import {Token} from "./models/user";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    token!: Token;

    constructor(
        private router: Router,
        private translate: TranslateService,
        private authenticationService: AuthenticationService
    ) {
        this.authenticationService.token.subscribe(x => {
            this.token = x;
            // if (!this.token) {
            //     this.router.navigate(['/login']);
            // }
        });
        translate.addLangs(['fr', 'ar', 'en']);
        // translate.setDefaultLang('fr');
        translate.use('fr');
    }
}
