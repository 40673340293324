<!-- Hero Section -->
<div class="hero-section">
    <div class="hero-content">
        <h1>Explorez les opportunités dans le secteur de la pêche</h1>
        <input type="text" class="form-control" placeholder="Rechercher des emplois, des ressources...">
    </div>
</div>

<!-- Job Listings Section -->
<section class="job-listings mt-5">
    <h2>Offres d'emploi en vedette</h2>
    <div class="job-cards">
        <app-job-card *ngFor="let job of data.get(contentType.Job)" [job]="job"></app-job-card>
    </div>
</section>

<!-- Training Programs Section -->
<section class="training-programs mt-5">
    <h2>Programmes de formation disponibles</h2>
    <div class="training-grid">
        <app-training-card *ngFor="let program of data.get(contentType.Training)" [program]="program"></app-training-card>
    </div>
</section>

<!-- News Section -->
<section class="news-updates mt-5">
    <h2>Dernières nouvelles</h2>
    <div class="news-articles">
        <app-news-article *ngFor="let article of data.get(contentType.News)" [article]="article"></app-news-article>
    </div>
</section>
