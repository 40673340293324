import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../../services/authentication.service";

@Component({
    selector: 'app-account-setup',
    templateUrl: './account-setup.component.html',
    styleUrls: ['./account-setup.component.scss']
})
export class AccountSetupComponent implements OnInit {
    passwordSetupForm: FormGroup;
    token: string;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private authService: AuthenticationService,
        private route: ActivatedRoute
    ) {
        this.passwordSetupForm = this.fb.group({
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', Validators.required]
        }, {validator: this.passwordMatchValidator});
    }

    ngOnInit(): void {
        this.token = this.route.snapshot.params.token;
    }

    passwordMatchValidator(form: FormGroup) {
        return form.get('password')?.value === form.get('confirmPassword')?.value ? null : {mismatch: true};
    }

    onSubmit() {
        if (this.passwordSetupForm.valid) {
            // Logic to handle password setup can be implemented here
            this.authService.setUpAccount(this.token, this.passwordSetupForm.value.password).subscribe(() => {
                this.router.navigate(['/login']);
            });
        }
    }
}
