import {Component, Input} from '@angular/core';
import {ContentItem} from "../../../models/ContentItem";

@Component({
  selector: 'app-training-card',
  templateUrl: './training-card.component.html',
  styleUrls: ['./training-card.component.scss']
})
export class TrainingCardComponent {

    @Input()
    program!: ContentItem

}
