<div class="login-container">
    <div class="login-box">
        <h2>Login to Your Account</h2>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="username">Email</label>
                <input type="username" id="username" class="form-control" formControlName="username" />
                <div *ngIf="form.get('username')?.invalid && form.get('username')?.touched" class="text-danger">
                    Enter a valid email.
                </div>
            </div>

            <div class="form-group">
                <label for="password">Password</label>
                <input type="password" id="password" class="form-control" formControlName="password" />
                <div *ngIf="form.get('password')?.invalid && form.get('password')?.touched" class="text-danger">
                    Password is required.
                </div>
            </div>

            <button type="submit" class="btn btn-primary btn-block" [disabled]="form.invalid">Login</button>
        </form>
        <p class="auth-link">Don't have an account? <a routerLink="/signup">Sign Up</a></p>
    </div>
</div>
