<div class="password-setup-container">
    <div class="password-setup-box">
        <h2>Set Up Your Password</h2>
        <form [formGroup]="passwordSetupForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="password">New Password</label>
                <input type="password" id="password" class="form-control" formControlName="password" />
                <div *ngIf="passwordSetupForm.get('password')?.invalid && passwordSetupForm.get('password')?.touched" class="text-danger">
                    Password is required and must be at least 6 characters.
                </div>
            </div>

            <div class="form-group">
                <label for="confirmPassword">Confirm New Password</label>
                <input type="password" id="confirmPassword" class="form-control" formControlName="confirmPassword" />
                <div *ngIf="passwordSetupForm.get('confirmPassword')?.invalid && passwordSetupForm.get('confirmPassword')?.touched" class="text-danger">
                    Passwords must match.
                </div>
            </div>

            <button type="submit" class="btn btn-primary btn-block" [disabled]="passwordSetupForm.invalid">Set Password</button>
        </form>
    </div>
</div>
