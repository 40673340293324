import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {appRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from "./shared/shared.module";
import {ConfirmDialogComponent} from "./shared/confirm-dialog/confirm-dialog.component";
import {TopbarComponent} from "./components/layout/topbar/topbar.component";
import {SidebarComponent} from "./components/layout/sidebar/sidebar.component";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {FormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {ToastrModule} from "ngx-toastr";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {MdbModalModule} from "mdb-angular-ui-kit/modal";
import {NgxSpinnerModule} from "ngx-spinner";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {registerLocaleData} from "@angular/common";
import localeFr from '@angular/common/locales/fr';
import {JwtInterceptor} from "./helpers/jwt.interceptor";
import {ConfirmationDialogService} from "./shared/ConfirmationDialogService";
import {ErrorInterceptor} from "./helpers/error.interceptor";
import {RbacModule} from "./shared/rbac/rbac.module";
import { JobCardComponent } from './modules/public/job-card/job-card.component';
import { TrainingCardComponent } from './modules/public/training-card/training-card.component';
import { NewsArticleComponent } from './modules/public/news-article/news-article.component';
import { FooterComponent } from './modules/public/footer/footer.component';
import { NavbarComponent } from './modules/public/navbar/navbar.component';
import {AccountSetupComponent} from "./modules/auth/account-setup/account-setup.component";
import {LoginComponent} from "./modules/auth/login/login.component";
import { HomeComponent } from './modules/public/home/home.component';
import { SignUpComponent } from './modules/auth/sign-up/sign-up.component';

registerLocaleData(localeFr);

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        TopbarComponent,
        SidebarComponent,
        ConfirmDialogComponent,
        AccountSetupComponent,
        JobCardComponent,
        TrainingCardComponent,
        NewsArticleComponent,
        FooterComponent,
        NavbarComponent,
        HomeComponent,
        SignUpComponent
    ],
    imports: [
        BrowserModule,
        appRoutingModule,
        HttpClientModule,
        FormsModule,
        BrowserAnimationsModule,
        FontAwesomeModule,
        ToastrModule.forRoot({}),
        TranslateModule.forRoot(
            {
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient]
                }
            }
        ),
        NgbModule,
        MdbModalModule,
        SharedModule,
        NgxSpinnerModule,
        RbacModule
    ],
    exports: [
        TranslateModule
    ],
    // entryComponents: [
    //   ConfirmDialogComponent
    // ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        ConfirmationDialogService,
        {provide: LOCALE_ID, useValue: "fr-FR"}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
