<ag-grid-angular
    #grid
    class="ag-theme-alpine"
    [columnDefs]="gridOptions.columnDefs"
    [localeText]="localeText"
    [gridOptions]="gridOptions"
    (gridReady)="gridReady($event)"
    [rowData]="rowData"
    [overlayLoadingTemplate]="overlayLoadingTemplate"
    [domLayout]="domLayout"
>
</ag-grid-angular>
