export interface ContentItem {
    id: string;                      // Unique identifier for the item
    title: string;                   // Title of the item
    description: string;             // Short description or summary
    contentType: ContentType;        // Enum or string indicating the type (job, news, etc.)
    datePublished: Date;             // Date when the item was published
    author?: string;                 // Optional: Author or creator of the item
    location?: string;               // Optional: Location, relevant for events or job offers
    tags?: string[];                 // Tags or categories for filtering and searching
    link?: string;                   // Optional: Link to the full content or external source
    imageUrl?: string;               // Optional: URL of an associated image
    isFeatured?: boolean;            // Optional: Whether the item is featured
    deadline?: Date;                // Optional: Deadline or closing date for applications
    views?: number;                  // Optional: Number of views or visits
    applications?: number;           // Optional: Number of applications or submissions
}

export enum ContentType {
    Job = 'Job',
    News = 'News',
    Training = 'Training',
    Offer = 'Offer',
    Event = 'Event',
    Other = 'Other'
}
