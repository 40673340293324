import {Component, Input} from '@angular/core';
import {ContentItem} from "../../../models/ContentItem";

@Component({
  selector: 'app-news-article',
  templateUrl: './news-article.component.html',
  styleUrls: ['./news-article.component.scss']
})
export class NewsArticleComponent {


    @Input()
    article!: ContentItem

}
