<div class="signup-container">
    <div class="signup-box">
        <h2>Create Your Account</h2>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="fullName">Full Name</label>
                <input type="text" id="fullName" class="form-control" formControlName="fullName" />
                <div *ngIf="form.get('fullName')?.invalid && form.get('fullName')?.touched" class="text-danger">
                    Full Name is required.
                </div>
            </div>

            <div class="form-group">
                <label for="email">Email</label>
                <input type="email" id="email" class="form-control" formControlName="email" />
                <div *ngIf="form.get('email')?.invalid && form.get('email')?.touched" class="text-danger">
                    Enter a valid email.
                </div>
            </div>

            <button type="submit" class="btn btn-primary btn-block" [disabled]="form.invalid">Sign Up</button>
        </form>
        <p class="auth-link">Already have an account? <a routerLink="/login">Login</a></p>
    </div>
</div>
