<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="/">Portail de pêche</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ml-auto">
            <ng-container *ngFor="let item of menuItems">
                <li class="nav-item" *ngIf="hasAccess(item)" >
                    <a class="nav-link" routerLink="{{item.link}}">{{item.label}}</a>
                </li>
            </ng-container>
            <li class="nav-item" *ngIf="!isLoggedIn">
                <a class="nav-link" routerLink="/login">Connexion</a>
            </li>
            <li class="nav-item pointer" *ngIf="isLoggedIn">
                <a class="nav-link" (click)="logout()">Logout</a>
            </li>
        </ul>
    </div>
</nav>
