import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "../../../services/authentication.service";
import {Router} from "@angular/router";
import {User} from "../../../models/user";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
    form: FormGroup;

    constructor(
        private authService: AuthenticationService,
        private router: Router,
        private fb: FormBuilder,
        private toastr: ToastrService
    ) {
    }

    ngOnInit(): void {
        this.initForm();
    }

    onSignup() {
        const user = this.form.value as User;
        if (this.authService.signup(user)) {
            this.router.navigate(['/']);
        } else {
            alert('Signup failed. Please try again.');
        }
    }

    private initForm() {
        this.form = this.fb.group({
            fullName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]]
        });
    }

    onSubmit() {
        if (this.form.valid) {
            const data = this.form.value;
            data.username = data.email;
            this.authService.signup(data)
                .subscribe(() => {
                    this.toastr.success('verifier votre email pour activer votre compte');
                    this.router.navigate(['/']);
                });

        }
    }

}
