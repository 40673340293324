<header class="topbar">
    <div class="brand">
        <img routerLink="/" class="pointer logo" src="assets/images/login-bg.jpg" alt="Logo"/>
    </div>
    <div class="topbar-left">
        <ul>
            <li>
                <a routerLinkActive="active" routerLink="/home" class="pointer">{{ 'nav.home' | translate }}</a>
            </li>
            <li>
                <a routerLinkActive="active" routerLink="/directory" class="pointer">{{ 'nav.directories' | translate }}</a>
            </li>
            <li>
                <a routerLinkActive="active" routerLink="/category" class="pointer">{{ 'nav.categories' | translate }}</a>
            </li>
        </ul>
    </div>
    <div class="topbar-right">
        <ul [hasRole]="['ADMIN']">
            <li>
                <a routerLinkActive="active" routerLink="/users" class="pointer">{{ 'nav.users' | translate }}</a>
            </li>
            <li>
                <a routerLinkActive="active" routerLink="/organigramme" class="pointer">{{ 'nav.organization-chart' | translate }}</a>
            </li>
        </ul>
        <div class="language-selector">
            <i (click)="toggleLanguageMenu()" class="fas fa-globe pointer"></i>
            <div class="language-dropdown" *ngIf="showLanguageMenu">
                <ul class="flex-column">
                    <li (click)="selectLanguage('ar')">Arabe</li>
                    <li (click)="selectLanguage('fr')">Français</li>
                    <li (click)="selectLanguage('en')">English</li>
                </ul>
            </div>
        </div>
        <div class="user-profile pointer">
            <i (click)="toggleUserProfile()" class="fas fa-user-circle"></i>
            <div class="language-dropdown" *ngIf="showUserProfile">
                <ul class="flex-column">
                    <li>
                        <a routerLink="/profile" class="pointer">{{ connectedUser?.username }}</a>
                    </li>
                    <li>
                        <span (click)="logout()" class="pointer">{{'global.logout' | translate}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</header>
