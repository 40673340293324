import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "./helpers/auth.guard";
import {LoginComponent} from "./modules/auth/login/login.component";
import {AccountSetupComponent} from "./modules/auth/account-setup/account-setup.component";
import {HomeComponent} from "./modules/public/home/home.component";
import {SignUpComponent} from "./modules/auth/sign-up/sign-up.component";

const routes: Routes = [
    {
        path: 'home',
        component: HomeComponent
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'signup',
        component: SignUpComponent
    },
    {
        path: 'users',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/user-management/user-management.module').then(m => m.UserManagementModule)
    },
    {
        path: 'submissions',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/submission/submission.module').then(m => m.SubmissionModule)
    },
    {
        path: 'administration',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
    },
    {path: 'account/setup/:token', component: AccountSetupComponent},
    {
        path: '**',
        redirectTo: 'home',
        pathMatch: 'full'
    }
];
export const appRoutingModule = RouterModule.forRoot(routes);
