import {Component, OnInit} from '@angular/core';
import {ContentItem, ContentType} from "../../../models/ContentItem";
import {ContentService} from "../../../services/content.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    data: Map<ContentType, ContentItem[]> = new Map<ContentType, ContentItem[]>();
    contentType = ContentType;

    constructor(
        private contentService: ContentService
    ) {
    }

    ngOnInit(): void {
        this.fetchData();
    }


    private fetchData() {
        this.contentService.getAll().subscribe(data => {
            this.data = data;
        });
    }

}
