import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ContentItem, ContentType} from "../models/ContentItem";
import {of} from "rxjs";

@Injectable({providedIn: 'root'})
export class ContentService {

    private url = `${environment.backendPrefix}/api/v1/content`;

    constructor(private http: HttpClient) {
    }

    getAll() {
        return of(this.fakeData)
        // return this.http.get<ContentItem[]>(this.url);
    }

    getByType(type: ContentType) {
        return this.http.get<ContentItem[]>(`${this.url}/by-type/${type}`);
    }

    fakeData: Map<ContentType, ContentItem[]> = new Map([
        [
            ContentType.Job, [
            {
                id: '1',
                title: 'Capitaine de Pêche Expérimenté Recherché',
                description: 'Nous recherchons un capitaine de pêche expérimenté pour diriger nos opérations au large de Nouadhibou.',
                contentType: ContentType.Job,
                datePublished: new Date(),
                author: 'Compagnie Mauritanienne de Pêche',
                location: 'Nouadhibou',
                tags: ['Pêche', 'Capitaine', 'Nouadhibou'],
                link: 'https://example.com/capitaine-peche',
                imageUrl: 'https://example.com/image1.jpg',
                isFeatured: true,
                deadline: new Date(new Date().setDate(new Date().getDate() + 30)),
                views: 150,
                applications: 5
            }
        ]
        ],
        [
            ContentType.News, [
            {
                id: '2',
                title: 'Augmentation des Quotas de Pêche pour 2024',
                description: 'Le gouvernement mauritanien a annoncé une augmentation des quotas de pêche pour l’année 2024.',
                contentType: ContentType.News,
                datePublished: new Date(),
                author: 'Agence Mauritanienne de Presse',
                tags: ['Quotas', 'Pêche', '2024'],
                link: 'https://example.com/quotas-peche-2024',
                imageUrl: 'https://example.com/image2.jpg',
                isFeatured: false,
                views: 300
            }
        ]
        ],
        [
            ContentType.Training, [
            {
                id: '3',
                title: 'Formation en Gestion Durable des Ressources Marines',
                description: 'Une formation intensive sur la gestion durable des ressources marines sera organisée à Nouakchott.',
                contentType: ContentType.Training,
                datePublished: new Date(),
                author: 'Institut de Pêche de Mauritanie',
                location: 'Nouakchott',
                tags: ['Formation', 'Gestion durable', 'Ressources marines'],
                link: 'https://example.com/formation-gestion-durable',
                imageUrl: 'https://example.com/image3.jpg',
                isFeatured: true,
                deadline: new Date(new Date().setDate(new Date().getDate() + 60)),
                views: 85
            }
        ]
        ],
        [
            ContentType.Offer, [
            {
                id: '4',
                title: 'Offre d\'Achat de Filets de Pêche',
                description: 'Une entreprise locale offre des filets de pêche à des prix compétitifs pour les pêcheurs de la région.',
                contentType: ContentType.Offer,
                datePublished: new Date(),
                author: 'Fournisseur de Filets Mauritanie',
                tags: ['Offre', 'Filets de pêche', 'Fournisseur'],
                link: 'https://example.com/achat-filets-peche',
                imageUrl: 'https://example.com/image4.jpg',
                isFeatured: false,
                views: 120
            }
        ]
        ],
        [
            ContentType.Event, [
            {
                id: '5',
                title: 'Conférence Annuelle sur la Pêche en Mauritanie',
                description: 'La conférence annuelle sur la pêche en Mauritanie se tiendra à Nouakchott avec des experts de l\'industrie.',
                contentType: ContentType.Event,
                datePublished: new Date(),
                author: 'Ministère de la Pêche',
                location: 'Nouakchott',
                tags: ['Conférence', 'Pêche', 'Mauritanie'],
                link: 'https://example.com/conference-peche',
                imageUrl: 'https://example.com/image5.jpg',
                isFeatured: true,
                deadline: new Date(new Date().setDate(new Date().getDate() + 90)),
                views: 200
            }
        ]
        ]
    ]);


}
