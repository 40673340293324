import {Component, Input, OnInit} from '@angular/core';
import {DomLayoutType, GridApi, GridOptions} from "ag-grid-community";
import {AG_GRID_LOCALE_FR} from "../ag-grid-i18n/locale.fr";
import {AgGridActionsButtonsComponent} from "../ag-grid-actions-buttons/ag-grid-actions-buttons.component";

@Component({
    selector: 'app-ag-grid-wrapper',
    templateUrl: './ag-grid-wrapper.component.html',
    styleUrls: ['./ag-grid-wrapper.component.scss']
})
export class AgGridWrapperComponent implements OnInit {
    @Input()
    columns: any[];
    @Input()
    rowData: any[];
    @Input()
    domLayout = 'autoHeight' as DomLayoutType;
    overlayLoadingTemplate;

    frameworkComponents: any;
    gridOptions: GridOptions;
    gridApi: GridApi;
    localeText: any;

    constructor() {
    }

    ngOnInit(): void {
        this.agGridColumnConfig();
        this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Chargement en cours ...</span>';
    }


    gridReady(params) {
        this.gridApi = params.api;
    }

    agGridColumnConfig() {
        this.frameworkComponents = {
            buttonRenderer: AgGridActionsButtonsComponent,
        };
        this.localeText = this.initAgGridLocale();
        this.gridOptions = <GridOptions>{
            rowHeight: 45,
            pagination: true,
            paginationPageSizeSelector: [10, 25, 50, 100],
            rowModelType: 'clientSide',
            enableColResize: true,
            enableSorting: false,
            enableFilter: true,
            defaultColDef: {
                sortable: true,
                filter: true,
                floatingFilter: false,
            },
            columnDefs: this.columns,
            deltaRowDataMode: true,
            getRowNodeId: (data) => {
                return data.id;
            },
        };
    }

    initAgGridLocale() {
        const AG_GRID_LOCAL = {};
        Object.keys(AG_GRID_LOCALE_FR).forEach(function (key) {
            AG_GRID_LOCAL[key] = AG_GRID_LOCALE_FR[key];
        });
        return AG_GRID_LOCAL;
    }

}
