import {Component} from '@angular/core';
import {AuthenticationService} from "../../../services/authentication.service";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
    isLoggedIn: boolean = false;
    menuItems = [
        {label: 'Accueil', link: '/'},
        {label: 'Submissions', link: '/submissions', authenticated: true},
        {label: 'Administration', link: '/administration', authenticated: true, hasRole: 'ADMIN'},
    ];

    constructor(private authService: AuthenticationService) {
        this.authService.token.subscribe(() => {
            this.isLoggedIn = this.authService.tokenValue?.authenticated;
        })
    }

    logout() {
        this.authService.logout();
    }

    hasAccess(item) {
        if(item.authenticated && !this.isLoggedIn) {
            return false;
        }
        if(item.hasRole) {
            return this.authService.tokenValue?.user.permissions.includes(item.hasRole);
        }
        return true;
    }
}
